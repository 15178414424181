import React, { useRef } from "react";
import UserMenu from "../partials/header/UserMenu";

const AlertTable = () => {
  let alerts = JSON.parse(localStorage.getItem("extractedFaults"));

  const downloadLinkRef = useRef(null);

  const downloadCSV = () => {
    const csvContent =
      "Time,Description\n" +
      alerts.map((alert) => `${alert.time1},${alert.fault}`).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const downloadURL = URL.createObjectURL(blob);

    downloadLinkRef.current.href = downloadURL;
    downloadLinkRef.current.click();
    URL.revokeObjectURL(downloadURL);
  };

  return (
    <div className="bg-indigo-500 h-screen">
      <header className="sticky top-0 z-30 bg-indigo-800 p-1  flex flex-wrap justify-end items-center gap-2 ">
        <div className="p-1">
          <UserMenu />
        </div>
      </header>
      <div className="container mx-auto p-4 font-serif">
        {/* <h1 className="text-2xl font-bold mb-4 text-center">Alerts</h1> */}
        <div className="p-5 bg-white m-1 rounded-lg bg-opacity-70 backdrop-filter backdrop-blur-lg font-sans">
          <div className="overflow-auto rounded-lg shadow">
            <table className="table-auto w-full  border-2 border-gray-500  ">
              <thead className="border-b-2 border-black bg-violet-400 text-white">
                <tr className="">
                  <th className="border-r-2 border-black p-2">Time</th>
                  <th className="p-2">Description</th>
                </tr>
              </thead>
              <tbody className="">
                {alerts.map((alert, index) => (
                  <tr
                    key={alert.id}
                    className={`${
                      index % 2 === 0 ? "bg-purple-100" : "bg-violet-200 "
                    }`}
                  >
                    <td className="border-r-2 border-black border-b-0 p-2 text-center">
                      {alert.time1}
                    </td>
                    <td className=" border-b-0 p-2 text-center">
                      {alert.fault}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex justify-center m-2 pt-2">
            <a
              href="#"
              ref={downloadLinkRef}
              style={{ display: "none" }}
              download="alerts.csv"
            >
              Download
            </a>
            <button
              className="btn font-inter bg-purple-400 text-white"
              onClick={() => downloadCSV()}
            >
              Download
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertTable;
