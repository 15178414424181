import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const GaugeChart = ({ value, maxValue, unit, colour }) => {
  const percentage = (value / maxValue) * 100;

  return (
    <div
      style={{
        // width: "100%", // Set width to 100% for responsiveness
        maxWidth: "150px", // Set a maximum width if needed
        height: "100px",
        paddingBottom: "0",
        width: "160px",

        // transform: "rotate(-90deg)",
        // transformOrigin: "center",
      }}
    >
      <CircularProgressbar
        value={percentage}
        text={`${value}${unit}`}
        circleRatio={0.5}
        styles={buildStyles({
          textSize: "17px",
          pathTransitionDuration: 0.5,
          pathColor: `${colour}`,
          textColor: "#000000",
          trailColor: "#FFFFFF",
          backgroundColor: "#FFFFFF",
          rotation: -0.25,
        })}
      />
    </div>
  );
};

export default GaugeChart;
