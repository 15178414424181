// import "./App.css";
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";
// Import pages
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Table from "./pages/Table";
import "./css/style.css";
import AlertTable from "./pages/AlertTable";

function App() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        {/* <Route exact path="/" element={<Barchart />} /> */}
        <Route exact path="/" element={<Login />} />
        <Route exact path="/Home" element={<Home />} />
        <Route exact path="/Dashboard" element={<Dashboard />} />
        <Route exact path="/Table" element={<Table />} />
        <Route exact path="/AlertTable" element={<AlertTable />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
