import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import UserMenu from "../partials/header/UserMenu";

const Table = () => {
  let panelinfo = JSON.parse(localStorage.getItem("data"));
  console.log(panelinfo);
  let [mqttdata, setMqttData] = useState([]);
  mqttdata = JSON.parse(localStorage.getItem("mqttdata"));

  const SendString = localStorage.getItem("sendmsg");
  const sendmsg = eval("(" + SendString + ")");

  const [vl1under, setvl1under] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][68] : 0
  );
  console.log(vl1under);
  const [vl2under, setvl2under] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][69] : 0
  );
  console.log(vl2under);
  const [vl3under, setvl3under] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][70] : 0
  );
  console.log(vl3under);

  const [vl1over, setvl1over] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][71] : 0
  );
  const [vl2over, setvl2over] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][72] : 0
  );
  const [vl3over, setvl3over] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][73] : 0
  );

  const [vl1imb, setvl1imb] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][74] : 0
  );
  const [vl2imb, setvl2imb] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][75] : 0
  );
  const [vl3imb, setvl3imb] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][76] : 0
  );

  const [vl1mul, setvl1mul] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][100] : 0
  );
  const [vl2mul, setvl2mul] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][101] : 0
  );
  const [vl3mul, setvl3mul] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][102] : 0
  );

  const [il1under, setil1under] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][77] : 0
  );
  console.log(vl1under);
  const [il2under, setil2under] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][78] : 0
  );
  console.log(vl2under);
  const [il3under, setil3under] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][79] : 0
  );
  console.log(vl3under);

  const [il1over, setil1over] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][80] : 0
  );
  const [il2over, setil2over] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][81] : 0
  );
  const [il3over, setil3over] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][82] : 0
  );

  const [il1imb, setil1imb] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][83] : 0
  );
  const [il2imb, setil2imb] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][84] : 0
  );
  const [il3imb, setil3imb] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][85] : 0
  );

  const [il1zero, setil1zero] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][86] : 0
  );
  const [il2zero, setil2zero] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][87] : 0
  );
  const [il3zero, setil3zero] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][88] : 0
  );

  const [il1mul, setil1mul] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][103] : 0
  );
  const [il2mul, setil2mul] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][104] : 0
  );
  const [il3mul, setil3mul] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][105] : 0
  );

  let [vl1ED, setv1ED] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][116] : 0
  );
  let [vl2ED, setv2ED] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][117] : 0
  );
  let [vl3ED, setv3ED] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][118] : 0
  );

  let [vl1overED, setv1overED] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][119] : 0
  );
  let [vl2overED, setv2overED] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][120] : 0
  );
  let [vl3overED, setv3overED] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][121] : 0
  );

  let [vl1imbED, setv1imbED] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][122] : 0
  );
  let [vl2imbED, setv2imbED] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][123] : 0
  );
  let [vl3imbED, setv3imbED] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][124] : 0
  );

  let [il1ED, seti1ED] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][125] : 0
  );
  let [il2ED, seti2ED] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][126] : 0
  );
  let [il3ED, seti3ED] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][127] : 0
  );

  let [il1overED, seti1overED] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][128] : 0
  );
  let [il2overED, seti2overED] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][129] : 0
  );
  let [il3overED, seti3overED] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][130] : 0
  );

  let [il1imbED, seti1imbED] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][131] : 0
  );
  let [il2imbED, seti2imbED] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][132] : 0
  );
  let [il3imbED, seti3imbED] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][133] : 0
  );

  let [il1zeroED, seti1zeroED] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][134] : 0
  );
  let [il2zeroED, seti2zeroED] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][135] : 0
  );
  let [il3zeroED, seti3zeroED] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][136] : 0
  );

  function convertToHM(value) {
    const hours = Math.floor(value / 60);
    const remainingMinutes = value % 60;
    const hours24 = hours % 24;
    return `${hours24.toString().padStart(2, "0")}:${remainingMinutes
      .toString()
      .padStart(2, "0")}`;
  }

  //   slots start time
  let [slot1startT, setSlot1startT] = useState(
    mqttdata?.[panelinfo.cardid]
      ? convertToHM(mqttdata[panelinfo.cardid][90])
      : "00:00"
  );
  console.log(slot1startT);
  let [slot2startT, setSlot2startT] = useState(
    mqttdata?.[panelinfo.cardid]
      ? convertToHM(mqttdata[panelinfo.cardid][91])
      : "00:00"
  );
  console.log(slot2startT);
  let [slot3startT, setSlot3startT] = useState(
    mqttdata?.[panelinfo.cardid]
      ? convertToHM(mqttdata[panelinfo.cardid][92])
      : "00:00"
  );
  console.log(slot3startT);
  let [slot4startT, setSlot4startT] = useState(
    mqttdata?.[panelinfo.cardid]
      ? convertToHM(mqttdata[panelinfo.cardid][93])
      : "00:00"
  );
  console.log(slot4startT);
  let [slot5startT, setSlot5startT] = useState(
    mqttdata?.[panelinfo.cardid]
      ? convertToHM(mqttdata[panelinfo.cardid][94])
      : "00:00"
  );
  // let [slot5startT, setSlot5startT] = useState("00:00");

  console.log(slot5startT);
  let [slot6startT, setSlot6startT] = useState(
    mqttdata?.[panelinfo.cardid]
      ? convertToHM(mqttdata[panelinfo.cardid][95])
      : "00:00"
  );
  console.log(slot6startT);
  let [slot7startT, setSlot7startT] = useState(
    mqttdata?.[panelinfo.cardid]
      ? convertToHM(mqttdata[panelinfo.cardid][96])
      : "00:00"
  );
  console.log(slot7startT);
  let [slot8startT, setSlot8startT] = useState(
    mqttdata?.[panelinfo.cardid]
      ? convertToHM(mqttdata[panelinfo.cardid][97])
      : "00:00"
  );
  console.log(slot8startT);
  let [slot9startT, setSlot9startT] = useState(
    mqttdata?.[panelinfo.cardid]
      ? convertToHM(mqttdata[panelinfo.cardid][98])
      : "00:00"
  );
  console.log(slot9startT);
  let [slot10startT, setSlot10startT] = useState(
    mqttdata?.[panelinfo.cardid]
      ? convertToHM(mqttdata[panelinfo.cardid][99])
      : "00:00"
  );
  console.log(slot10startT);

  //   slots runtime
  let [slot1runTime, setSlot1runTime] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][40] : 0
  );
  console.log(slot1runTime);
  let [slot2runTime, setSlot2runTime] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][41] : 0
  );
  console.log(slot2runTime);
  let [slot3runTime, setSlot3runTime] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][42] : 0
  );
  console.log(slot3runTime);
  let [slot4runTime, setSlot4runTime] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][43] : 0
  );
  console.log(slot4runTime);
  let [slot5runTime, setSlot5runTime] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][44] : 0
  );
  console.log(slot5runTime);
  let [slot6runTime, setSlot6runTime] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][45] : 0
  );
  console.log(slot6runTime);
  let [slot7runTime, setSlot7runTime] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][46] : 0
  );
  console.log(slot7runTime);
  let [slot8runTime, setSlot8runTime] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][47] : 0
  );
  console.log(slot8runTime);
  let [slot9runTime, setSlot9runTime] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][48] : 0
  );
  console.log(slot9runTime);
  let [slot10runTime, setSlot10runTime] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][49] : 0
  );
  console.log(slot10runTime);

  //   slot enable/disable
  let [slot1ED, setSlot1ED] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][138] : 0
  );
  console.log(slot1ED);
  let [slot2ED, setSlot2ED] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][139] : 0
  );
  console.log(slot2ED);
  let [slot3ED, setSlot3ED] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][140] : 0
  );
  console.log(slot3ED);
  let [slot4ED, setSlot4ED] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][141] : 0
  );
  console.log(slot4ED);
  let [slot5ED, setSlot5ED] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][142] : 0
  );
  console.log(slot5ED);
  let [slot6ED, setSlot6ED] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][143] : 0
  );
  console.log(slot6ED);
  let [slot7ED, setSlot7ED] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][144] : 0
  );
  console.log(slot7ED);
  let [slot8ED, setSlot8ED] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][145] : 0
  );
  console.log(slot8ED);
  let [slot9ED, setSlot9ED] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][146] : 0
  );
  console.log(slot9ED);
  let [slot10ED, setSlot10ED] = useState(
    mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][147] : 0
  );
  console.log(slot10ED);

  let time1 = slot1startT.split(":");
  let paddedSlot1StartT = (Number(time1[0] * 60) + Number(time1[1]))
    .toString()
    .padStart(5, "0");
  console.log(paddedSlot1StartT);

  let time2 = slot2startT.split(":");
  let paddedSlot2StartT = (Number(time2[0] * 60) + Number(time2[1]))
    .toString()
    .padStart(5, "0");
  console.log(paddedSlot2StartT);

  let time3 = slot3startT.split(":");
  let paddedSlot3StartT = (Number(time3[0] * 60) + Number(time3[1]))
    .toString()
    .padStart(5, "0");
  console.log(paddedSlot3StartT);

  let time4 = slot4startT.split(":");
  let paddedSlot4StartT = (Number(time4[0] * 60) + Number(time4[1]))
    .toString()
    .padStart(5, "0");
  console.log(paddedSlot4StartT);

  let time5 = slot5startT.split(":");
  let paddedSlot5StartT = (Number(time5[0] * 60) + Number(time5[1]))
    .toString()
    .padStart(5, "0");
  console.log(paddedSlot5StartT);

  let time6 = slot6startT.split(":");
  let paddedSlot6StartT = (Number(time6[0] * 60) + Number(time6[1]))
    .toString()
    .padStart(5, "0");
  console.log(paddedSlot6StartT);

  let time7 = slot7startT.split(":");
  let paddedSlot7StartT = (Number(time7[0] * 60) + Number(time7[1]))
    .toString()
    .padStart(5, "0");
  console.log(paddedSlot7StartT);

  let time8 = slot8startT.split(":");
  let paddedSlot8StartT = (Number(time8[0] * 60) + Number(time8[1]))
    .toString()
    .padStart(5, "0");
  console.log(paddedSlot8StartT);

  let time9 = slot9startT.split(":");
  let paddedSlot9StartT = (Number(time9[0] * 60) + Number(time9[1]))
    .toString()
    .padStart(5, "0");
  console.log(paddedSlot9StartT);

  let time10 = slot10startT.split(":");
  let paddedSlot10StartT = (Number(time10[0] * 60) + Number(time10[1]))
    .toString()
    .padStart(5, "0");
  console.log(paddedSlot10StartT);

  //
  let paddedslot1RunT = slot1runTime.toString().padStart(5, "0");
  let paddedslot2RunT = slot2runTime.toString().padStart(5, "0");
  let paddedslot3RunT = slot3runTime.toString().padStart(5, "0");
  let paddedslot4RunT = slot4runTime.toString().padStart(5, "0");
  let paddedslot5RunT = slot5runTime.toString().padStart(5, "0");
  let paddedslot6RunT = slot6runTime.toString().padStart(5, "0");
  let paddedslot7RunT = slot7runTime.toString().padStart(5, "0");
  let paddedslot8RunT = slot8runTime.toString().padStart(5, "0");
  let paddedslot9RunT = slot9runTime.toString().padStart(5, "0");
  let paddedslot10RunT = slot10runTime.toString().padStart(5, "0");

  //
  let paddedslot1ED = slot1ED.toString().padStart(5, "0");
  let paddedslot2ED = slot2ED.toString().padStart(5, "0");
  let paddedslot3ED = slot3ED.toString().padStart(5, "0");
  let paddedslot4ED = slot4ED.toString().padStart(5, "0");
  let paddedslot5ED = slot5ED.toString().padStart(5, "0");
  let paddedslot6ED = slot6ED.toString().padStart(5, "0");
  let paddedslot7ED = slot7ED.toString().padStart(5, "0");
  let paddedslot8ED = slot8ED.toString().padStart(5, "0");
  let paddedslot9ED = slot9ED.toString().padStart(5, "0");
  let paddedslot10ED = slot10ED.toString().padStart(5, "0");

  console.log(paddedslot1ED);

  let paddedVl1Under = vl1under.toString().padStart(5, "0");
  console.log(paddedVl1Under);
  let paddedVl2Under = vl2under.toString().padStart(5, "0");
  console.log(paddedVl2Under);
  let paddedVl3Under = vl3under.toString().padStart(5, "0");
  console.log(paddedVl3Under);

  let paddedVl1Over = vl1over.toString().padStart(5, "0");
  let paddedVl2Over = vl2over.toString().padStart(5, "0");
  let paddedVl3Over = vl3over.toString().padStart(5, "0");

  let paddedVl1imb = vl1imb.toString().padStart(5, "0");
  let paddedVl2imb = vl2imb.toString().padStart(5, "0");
  let paddedVl3imb = vl3imb.toString().padStart(5, "0");

  let paddedVl1mul = vl1mul.toString().padStart(5, "0");
  let paddedVl2mul = vl2mul.toString().padStart(5, "0");
  let paddedVl3mul = vl3mul.toString().padStart(5, "0");

  let paddedil1Under = il1under.toString().padStart(5, "0");
  console.log(paddedVl1Under);
  let paddedil2Under = il2under.toString().padStart(5, "0");
  console.log(paddedVl2Under);
  let paddedil3Under = il3under.toString().padStart(5, "0");
  console.log(paddedVl3Under);

  let paddedil1Over = il1over.toString().padStart(5, "0");
  let paddedil2Over = il2over.toString().padStart(5, "0");
  let paddedil3Over = il3over.toString().padStart(5, "0");

  let paddedil1imb = il1imb.toString().padStart(5, "0");
  let paddedil2imb = il2imb.toString().padStart(5, "0");
  let paddedil3imb = il3imb.toString().padStart(5, "0");

  let paddedil1zero = il1zero.toString().padStart(5, "0");
  let paddedil2zero = il2zero.toString().padStart(5, "0");
  let paddedil3zero = il3zero.toString().padStart(5, "0");

  let paddedil1mul = il1mul.toString().padStart(5, "0");
  let paddedil2mul = il2mul.toString().padStart(5, "0");
  let paddedil3mul = il3mul.toString().padStart(5, "0");

  const handleVl1under = (e) => {
    const value = e.target.value;

    setvl1under(e.target.value);
  };

  const handleVl2under = (e) => {
    setvl2under(e.target.value);
  };

  const handleVl3under = (e) => {
    setvl3under(e.target.value);
  };
  const handleIl1under = (e) => {
    setil1under(e.target.value);
  };

  const handleIl2under = (e) => {
    setil2under(e.target.value);
  };

  const handleIl3under = (e) => {
    setil3under(e.target.value);
  };

  const handleVl1over = (e) => {
    setvl1over(e.target.value);
  };

  const handleVl2over = (e) => {
    setvl2over(e.target.value);
  };

  const handleVl3over = (e) => {
    setvl3over(e.target.value);
  };
  const handleIl1over = (e) => {
    setil1over(e.target.value);
  };

  const handleIl2over = (e) => {
    setil2over(e.target.value);
  };

  const handleIl3over = (e) => {
    setil3over(e.target.value);
  };

  const handleVl1imb = (e) => {
    setvl1imb(e.target.value);
  };

  const handleVl2imb = (e) => {
    setvl2imb(e.target.value);
  };

  const handleVl3imb = (e) => {
    setvl3imb(e.target.value);
  };
  const handleIl1imb = (e) => {
    setil1imb(e.target.value);
  };

  const handleIl2imb = (e) => {
    setil2imb(e.target.value);
  };

  const handleIl3imb = (e) => {
    setil3imb(e.target.value);
  };

  const handleIl1zero = (e) => {
    setil1zero(e.target.value);
  };

  const handleIl2zero = (e) => {
    setil2zero(e.target.value);
  };

  const handleIl3zero = (e) => {
    setil3zero(e.target.value);
  };

  const handleVl1mul = (e) => {
    setvl1mul(e.target.value);
  };
  const handleVl2mul = (e) => {
    setvl2mul(e.target.value);
  };
  const handleVl3mul = (e) => {
    setvl3mul(e.target.value);
  };
  const handleIl1mul = (e) => {
    setil1mul(e.target.value);
  };
  const handleIl2mul = (e) => {
    setil2mul(e.target.value);
  };
  const handleIl3mul = (e) => {
    setil3mul(e.target.value);
  };

  const handlevl1ED = (e) => {
    let value = e.target.value;
    if (value >= 0 && value <= 1) {
      setv1ED(value);
    }
  };
  const handlevl2ED = (e) => {
    let value = e.target.value;
    if (value >= 0 && value <= 1) {
      setv2ED(value);
    }
  };
  const handlevl3ED = (e) => {
    let value = e.target.value;
    if (value >= 0 && value <= 1) {
      setv3ED(value);
    }
  };

  const handlevl1overED = (e) => {
    let value = e.target.value;
    if (value >= 0 && value <= 1) {
      setv1overED(value);
    }
  };
  const handlevl2overED = (e) => {
    let value = e.target.value;
    if (value >= 0 && value <= 1) {
      setv2overED(value);
    }
  };
  const handlevl3overED = (e) => {
    let value = e.target.value;
    if (value >= 0 && value <= 1) {
      setv3overED(value);
    }
  };

  const handlevl1imbED = (e) => {
    let value = e.target.value;
    if (value >= 0 && value <= 1) {
      setv1imbED(value);
    }
  };
  const handlevl2imbED = (e) => {
    let value = e.target.value;
    if (value >= 0 && value <= 1) {
      setv2imbED(value);
    }
  };
  const handlevl3imbED = (e) => {
    let value = e.target.value;
    if (value >= 0 && value <= 1) {
      setv3imbED(value);
    }
  };

  const handleil1ED = (e) => {
    let value = e.target.value;
    if (value >= 0 && value <= 1) {
      seti1ED(value);
    }
  };
  const handleil2ED = (e) => {
    let value = e.target.value;
    if (value >= 0 && value <= 1) {
      seti2ED(value);
    }
  };
  const handleil3ED = (e) => {
    let value = e.target.value;
    if (value >= 0 && value <= 1) {
      seti3ED(value);
    }
  };

  const handleil1overED = (e) => {
    let value = e.target.value;
    if (value >= 0 && value <= 1) {
      seti1overED(value);
    }
  };
  const handleil2overED = (e) => {
    let value = e.target.value;
    if (value >= 0 && value <= 1) {
      seti2overED(value);
    }
  };
  const handleil3overED = (e) => {
    let value = e.target.value;
    if (value >= 0 && value <= 1) {
      seti3overED(value);
    }
  };

  const handleil1imbED = (e) => {
    let value = e.target.value;
    if (value >= 0 && value <= 1) {
      seti1imbED(value);
    }
  };
  const handleil2imbED = (e) => {
    let value = e.target.value;
    if (value >= 0 && value <= 1) {
      seti2imbED(value);
    }
  };
  const handleil3imbED = (e) => {
    let value = e.target.value;
    if (value >= 0 && value <= 1) {
      seti3imbED(value);
    }
  };

  const handleil1zeroED = (e) => {
    let value = e.target.value;
    if (value >= 0 && value <= 1) {
      seti1zeroED(value);
    }
  };
  const handleil2zeroED = (e) => {
    let value = e.target.value;
    if (value >= 0 && value <= 1) {
      seti2zeroED(value);
    }
  };
  const handleil3zeroED = (e) => {
    let value = e.target.value;
    if (value >= 0 && value <= 1) {
      seti3zeroED(value);
    }
  };

  const handleslot1ED = (e) => {
    let value = e.target.value;
    if (value >= 0 && value <= 1) {
      setSlot1ED(value);
    }
  };
  const handleslot2ED = (e) => {
    let value = e.target.value;
    if (value >= 0 && value <= 1) {
      setSlot2ED(value);
    }
  };
  const handleslot3ED = (e) => {
    let value = e.target.value;
    if (value >= 0 && value <= 1) {
      setSlot3ED(value);
    }
  };
  const handleslot4ED = (e) => {
    let value = e.target.value;
    if (value >= 0 && value <= 1) {
      setSlot4ED(value);
    }
  };
  const handleslot5ED = (e) => {
    let value = e.target.value;
    if (value >= 0 && value <= 1) {
      setSlot5ED(value);
    }
  };
  const handleslot6ED = (e) => {
    let value = e.target.value;
    if (value >= 0 && value <= 1) {
      setSlot6ED(value);
    }
  };
  const handleslot7ED = (e) => {
    let value = e.target.value;
    if (value >= 0 && value <= 1) {
      setSlot7ED(value);
    }
  };
  const handleslot8ED = (e) => {
    let value = e.target.value;
    if (value >= 0 && value <= 1) {
      setSlot8ED(value);
    }
  };
  const handleslot9ED = (e) => {
    let value = e.target.value;
    if (value >= 0 && value <= 1) {
      setSlot9ED(value);
    }
  };
  const handleslot10ED = (e) => {
    let value = e.target.value;
    if (value >= 0 && value <= 1) {
      // console.log(value)
      setSlot10ED(value);
    }
  };

  // if (vl1ED === true) {
  //   vl1ED = 1;
  // } else {
  //   vl1ED = 0;
  // }

  // if (vl2ED === true) {
  //   vl2ED = 1;
  // } else {
  //   vl2ED = 0;
  // }

  // if (vl3ED === true) {
  //   vl3ED = 1;
  // } else {
  //   vl3ED = 0;
  // }
  // if (il1ED === true) {
  //   il1ED = 1;
  // } else {
  //   il1ED = 0;
  // }
  // if (il2ED === true) {
  //   il2ED = 1;
  // } else {
  //   il2ED = 0;
  // }
  // if (il3ED === true) {
  //   il3ED = 1;
  // } else {
  //   il3ED = 0;
  // }

  // console.log(vl1ED);
  let paddedvl1ED = vl1ED.toString().padStart(5, "0");
  let paddedvl2ED = vl2ED.toString().padStart(5, "0");
  let paddedvl3ED = vl3ED.toString().padStart(5, "0");
  let paddedvl1overED = vl1overED.toString().padStart(5, "0");
  let paddedvl2overED = vl2overED.toString().padStart(5, "0");
  let paddedvl3overED = vl3overED.toString().padStart(5, "0");
  let paddedvl1imbED = vl1imbED.toString().padStart(5, "0");
  let paddedvl2imbED = vl2imbED.toString().padStart(5, "0");
  let paddedvl3imbED = vl3imbED.toString().padStart(5, "0");

  let paddedil1ED = il1ED.toString().padStart(5, "0");
  let paddedil2ED = il2ED.toString().padStart(5, "0");
  let paddedil3ED = il3ED.toString().padStart(5, "0");
  let paddedil1overED = il1overED.toString().padStart(5, "0");
  let paddedil2overED = il2overED.toString().padStart(5, "0");
  let paddedil3overED = il3overED.toString().padStart(5, "0");
  let paddedil1imbED = il1imbED.toString().padStart(5, "0");
  let paddedil2imbED = il2imbED.toString().padStart(5, "0");
  let paddedil3imbED = il3imbED.toString().padStart(5, "0");
  let paddedil1zeroED = il1zeroED.toString().padStart(5, "0");
  let paddedil2zeroED = il2zeroED.toString().padStart(5, "0");
  let paddedil3zeroED = il3zeroED.toString().padStart(5, "0");

  let vl1underMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `068,001,${paddedVl1Under}`,
  };
  console.log(vl1underMessage);

  let vl2underMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `069,001,${paddedVl2Under}`,
  };
  console.log(vl2underMessage);

  let vl3underMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `070,001,${paddedVl3Under}`,
  };
  console.log(vl3underMessage);

  let vl1overMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `071,001,${paddedVl1Over}`,
  };
  console.log(vl1overMessage);
  let vl2overMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `072,001,${paddedVl2Over}`,
  };
  console.log(vl2overMessage);
  let vl3overMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `073,001,${paddedVl3Over}`,
  };
  console.log(vl3overMessage);
  let vl1ImbMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `074,001,${paddedVl1imb}`,
  };
  console.log(vl1ImbMessage);
  let vl2ImbMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `075,001,${paddedVl2imb}`,
  };
  console.log(vl2ImbMessage);
  let vl3ImbMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `076,001,${paddedVl3imb}`,
  };
  console.log(vl3ImbMessage);

  let vl1mulMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `100,001,${paddedVl1mul}`,
  };
  console.log(vl1mulMessage);
  let vl2mulMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `101,001,${paddedVl2mul}`,
  };
  console.log(vl2mulMessage);
  let vl3mulMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `102,001,${paddedVl3mul}`,
  };
  console.log(vl3mulMessage);

  let il1underMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `077,001,${paddedil1Under}`,
  };
  console.log(il1underMessage);

  let il2underMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `078,001,${paddedil2Under}`,
  };
  console.log(il2underMessage);

  let il3underMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `079,001,${paddedil3Under}`,
  };
  console.log(il3underMessage);

  let il1overMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `080,001,${paddedil1Over}`,
  };
  console.log(il1overMessage);
  let il2overMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `081,001,${paddedil2Over}`,
  };
  console.log(il2overMessage);
  let il3overMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `082,001,${paddedil3Over}`,
  };
  console.log(il3overMessage);
  let il1ImbMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `083,001,${paddedil1imb}`,
  };
  console.log(il1ImbMessage);
  let il2ImbMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `084,001,${paddedil2imb}`,
  };
  console.log(il2ImbMessage);
  let il3ImbMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `085,001,${paddedil3imb}`,
  };
  console.log(il3ImbMessage);
  let il1zeroMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `086,001,${paddedil1zero}`,
  };
  console.log(il1zeroMessage);
  let il2zeroMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `087,001,${paddedil2zero}`,
  };
  console.log(il2zeroMessage);
  let il3zeroMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `088,001,${paddedil3zero}`,
  };
  console.log(il3zeroMessage);
  let il1mulMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `103,001,${paddedil1mul}`,
  };
  console.log(il1mulMessage);
  let il2mulMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `104,001,${paddedil2mul}`,
  };
  console.log(il2mulMessage);
  let il3mulMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `105,001,${paddedil3mul}`,
  };
  console.log(il3mulMessage);

  let vl1EDmesaage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `116,001,${paddedvl1ED}`,
  };
  console.log(vl1EDmesaage);
  let vl2EDmesaage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `117,001,${paddedvl2ED}`,
  };
  console.log(vl2EDmesaage);
  let vl3EDmesaage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `118,001,${paddedvl3ED}`,
  };
  console.log(vl3EDmesaage);

  let vl1overEDmesaage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `119,001,${paddedvl1overED}`,
  };
  console.log(vl1overEDmesaage);
  let vl2overEDmesaage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `120,001,${paddedvl2overED}`,
  };
  console.log(vl2overEDmesaage);
  let vl3overEDmesaage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `121,001,${paddedvl3overED}`,
  };
  console.log(vl3overEDmesaage);

  let vl1imbEDmesaage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `122,001,${paddedvl1imbED}`,
  };
  console.log(vl1imbEDmesaage);
  let vl2imbEDmesaage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `123,001,${paddedvl2imbED}`,
  };
  console.log(vl2imbEDmesaage);
  let vl3imbEDmesaage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `124,001,${paddedvl3imbED}`,
  };
  console.log(vl3imbEDmesaage);

  let il1EDmesaage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `125,001,${paddedil1ED}`,
  };
  console.log(il1EDmesaage);
  let il2EDmesaage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `126,001,${paddedil2ED}`,
  };
  console.log(il2EDmesaage);
  let il3EDmesaage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `127,001,${paddedil3ED}`,
  };
  console.log(il3EDmesaage);

  let il1overEDmesaage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `128,001,${paddedil1overED}`,
  };
  console.log(il1overEDmesaage);
  let il2overEDmesaage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `129,001,${paddedil2overED}`,
  };
  console.log(il2overEDmesaage);
  let il3overEDmesaage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `130,001,${paddedil3overED}`,
  };
  console.log(il3overEDmesaage);

  let il1imbEDmesaage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `131,001,${paddedil1imbED}`,
  };
  console.log(il1imbEDmesaage);
  let il2imbEDmesaage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `132,001,${paddedil2imbED}`,
  };
  console.log(il2imbEDmesaage);
  let il3imbEDmesaage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `133,001,${paddedil3imbED}`,
  };
  console.log(il3imbEDmesaage);

  let il1zeroEDmesaage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `134,001,${paddedil1zeroED}`,
  };
  console.log(il1zeroEDmesaage);
  let il2zeroEDmesaage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `135,001,${paddedil2zeroED}`,
  };
  console.log(il2zeroEDmesaage);
  let il3zeroEDmesaage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `136,001,${paddedil3zeroED}`,
  };
  console.log(il3zeroEDmesaage);

  let slot1startTMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `090,001,${paddedSlot1StartT}`,
  };
  console.log(slot1startTMessage);
  let slot2startTMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `091,001,${paddedSlot2StartT}`,
  };
  console.log(slot2startTMessage);
  let slot3startTMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `092,001,${paddedSlot3StartT}`,
  };
  console.log(slot3startTMessage);
  let slot4startTMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `093,001,${paddedSlot4StartT}`,
  };
  console.log(slot4startTMessage);
  let slot5startTMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `094,001,${paddedSlot5StartT}`,
  };
  console.log(slot5startTMessage);
  let slot6startTMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `095,001,${paddedSlot6StartT}`,
  };
  console.log(slot6startTMessage);
  let slot7startTMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `096,001,${paddedSlot7StartT}`,
  };
  console.log(slot7startTMessage);
  let slot8startTMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `097,001,${paddedSlot8StartT}`,
  };
  console.log(slot8startTMessage);
  let slot9startTMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `098,001,${paddedSlot9StartT}`,
  };
  console.log(slot9startTMessage);
  let slot10startTMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `099,001,${paddedSlot10StartT}`,
  };
  console.log(slot10startTMessage);
  let slot1runTimeMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `040,001,${paddedslot1RunT}`,
  };
  console.log(slot1runTimeMessage);
  let slot2runTimeMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `041,001,${paddedslot2RunT}`,
  };
  console.log(slot2runTimeMessage);
  let slot3runTimeMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `042,001,${paddedslot3RunT}`,
  };
  console.log(slot3runTimeMessage);
  let slot4runTimeMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `043,001,${paddedslot4RunT}`,
  };
  console.log(slot4runTimeMessage);
  let slot5runTimeMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `044,001,${paddedslot5RunT}`,
  };
  console.log(slot5runTimeMessage);
  let slot6runTimeMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `045,001,${paddedslot6RunT}`,
  };
  console.log(slot6runTimeMessage);
  let slot7runTimeMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `046,001,${paddedslot7RunT}`,
  };
  console.log(slot7runTimeMessage);
  let slot8runTimeMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `047,001,${paddedslot8RunT}`,
  };
  console.log(slot8runTimeMessage);
  let slot9runTimeMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `048,001,${paddedslot9RunT}`,
  };
  console.log(slot9runTimeMessage);
  let slot10runTimeMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `049,001,${paddedslot10RunT}`,
  };
  console.log(slot10runTimeMessage);
  let slot1EDMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `138,001,${paddedslot1ED}`,
  };
  console.log(slot1EDMessage);
  let slot2EDMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `139,001,${paddedslot2ED}`,
  };
  console.log(slot2EDMessage);
  let slot3EDMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `140,001,${paddedslot3ED}`,
  };
  console.log(slot3EDMessage);
  let slot4EDMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `141,001,${paddedslot4ED}`,
  };
  console.log(slot4EDMessage);
  let slot5EDMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `142,001,${paddedslot5ED}`,
  };
  console.log(slot5EDMessage);
  let slot6EDMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `143,001,${paddedslot6ED}`,
  };
  console.log(slot6EDMessage);
  let slot7EDMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `144,001,${paddedslot7ED}`,
  };
  console.log(slot7EDMessage);
  let slot8EDMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `145,001,${paddedslot8ED}`,
  };
  console.log(slot8EDMessage);
  let slot9EDMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `146,001,${paddedslot9ED}`,
  };
  console.log(slot9EDMessage);
  let slot10EDMessage = {
    type: "config",
    id: 1,
    key: "carray",
    value: `147,001,${paddedslot10ED}`,
  };
  console.log(slot10EDMessage);

  const handleslot1StartT = (e) => {
    setSlot1startT(e.target.value);
  };
  const handleslot2StartT = (e) => {
    setSlot2startT(e.target.value);
  };
  const handleslot3StartT = (e) => {
    setSlot3startT(e.target.value);
  };
  const handleslot4StartT = (e) => {
    setSlot4startT(e.target.value);
  };
  const handleslot5StartT = (e) => {
    setSlot5startT(e.target.value);
  };
  const handleslot6StartT = (e) => {
    setSlot6startT(e.target.value);
  };
  const handleslot7StartT = (e) => {
    setSlot7startT(e.target.value);
  };
  const handleslot8StartT = (e) => {
    setSlot8startT(e.target.value);
  };
  const handleslot9StartT = (e) => {
    setSlot9startT(e.target.value);
  };
  const handleslot10StartT = (e) => {
    setSlot10startT(e.target.value);
  };

  const handleslot1RunTime = (e) => {
    setSlot1runTime(e.target.value);
  };
  const handleslot2RunTime = (e) => {
    setSlot2runTime(e.target.value);
  };
  const handleslot3RunTime = (e) => {
    setSlot3runTime(e.target.value);
  };
  const handleslot4RunTime = (e) => {
    setSlot4runTime(e.target.value);
  };
  const handleslot5RunTime = (e) => {
    setSlot5runTime(e.target.value);
  };
  const handleslot6RunTime = (e) => {
    setSlot6runTime(e.target.value);
  };
  const handleslot7RunTime = (e) => {
    setSlot7runTime(e.target.value);
  };
  const handleslot8RunTime = (e) => {
    setSlot8runTime(e.target.value);
  };
  const handleslot9RunTime = (e) => {
    setSlot9runTime(e.target.value);
  };
  const handleslot10RunTime = (e) => {
    setSlot10runTime(e.target.value);
  };
  console.log(panelinfo.imei);

  return (
    <div className="bg-indigo-500 h-full w-full p-0 m-0">
      <header className="sticky top-0 z-30 bg-indigo-800 p-1  flex flex-wrap justify-end items-center gap-2 ">
        <div className="p-1">
          <UserMenu />
        </div>
      </header>
      <div className="container mx-auto my-auto ">
        <div className="text-center p-2 text-xl text-gray-200 font-bold font-sans">
          VOLTAGE CONFIGURATION
        </div>
        {/* voltage config table */}
        <div className="m-auto sm:mx-5 lg:mx-6 md:mx-6 xl:mx-8 2xl:mx-10">
          <div className="overflow-auto rounded-lg shadow p-5 bg-white m-1  bg-opacity-70 backdrop-filter backdrop-blur-lg font-sans ">
            <table className="table-auto w-full mb-3 ">
              <thead className="border-b-2 border-gray-200">
                <tr>
                  <th className="border  p-2 bg-violet-400 text-gray-800  tracking-wide text-left text-sm font-semibold ">
                    Parameters
                  </th>
                  <th className="border  p-2 bg-violet-400 text-gray-800 tracking-wide text-left text-sm font-semibold">
                    Under Voltage Limit
                  </th>
                  <th className="border  p-2 bg-violet-400 text-gray-800 tracking-wide text-left text-sm font-semibold">
                    Over Voltage Limit
                  </th>
                  <th className="border  p-2 bg-violet-400 text-gray-800 tracking-wide text-left text-sm font-semibold">
                    Imbalance voltage Limit
                  </th>
                  <th className="border  p-2 bg-violet-400 text-gray-800 tracking-wide text-left text-sm font-semibold">
                    Multiplier
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* slot 1 */}
                <tr className="bg-purple-100">
                  <td className="border  p-2 font-bold text-center ">VL1</td>
                  <td className="border  p-2 ">
                    <div className="flex justify-center items-center">
                      <div className="flex flex-col justify-center items-center">
                        <input
                          type="number"
                          value={vl1under}
                          onChange={handleVl1under}
                          min={0}
                          max={440}
                          className="border-gray-100 rounded-lg p-1 mx-2 my-1 "
                        />
                        <button
                          onClick={() =>
                            sendmsg(panelinfo.imei, vl1underMessage)
                          }
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                      <div className="flex flex-col justify-center items-center">
                        <label className="inline-flex items-center">
                          <input
                            type="number"
                            min={0}
                            max={1}
                            value={vl1ED}
                            onChange={handlevl1ED}
                            className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                          />
                        </label>
                        <button
                          onClick={() => sendmsg(panelinfo.imei, vl1EDmesaage)}
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                    </div>
                  </td>
                  <td className="border  p-2 ">
                    <div className="flex justify-center items-center">
                      <div className="flex flex-col justify-center items-center">
                        <input
                          type="number"
                          value={vl1over}
                          onChange={handleVl1over}
                          min={0}
                          max={440}
                          className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                        />
                        {/* <div className="align-middle"> */}
                        <button
                          onClick={() =>
                            sendmsg(panelinfo.imei, vl1overMessage)
                          }
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                      <div className="flex flex-col justify-center items-center">
                        <label className="inline-flex items-center">
                          <input
                            type="number"
                            min={0}
                            max={1}
                            value={vl1overED}
                            onChange={handlevl1overED}
                            className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                          />
                        </label>
                        <button
                          onClick={() =>
                            sendmsg(panelinfo.imei, vl1overEDmesaage)
                          }
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                    </div>
                    {/* </div> */}
                  </td>
                  <td className="border  p-2  ">
                    <div className="flex justify-center items-center">
                      <div className="flex flex-col justify-center items-center">
                        <input
                          type="number"
                          value={vl1imb}
                          onChange={handleVl1imb}
                          min={0}
                          max={400}
                          className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                        />
                        <button
                          onClick={() => sendmsg(panelinfo.imei, vl1ImbMessage)}
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                      <div className="flex flex-col justify-center items-center">
                        <label className="inline-flex items-center">
                          <input
                            type="number"
                            min={0}
                            max={1}
                            value={vl1imbED}
                            onChange={handlevl1imbED}
                            className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                          />
                        </label>
                        <button
                          onClick={() =>
                            sendmsg(panelinfo.imei, vl1imbEDmesaage)
                          }
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                    </div>
                  </td>
                  <td className="border  p-2 ">
                    <div className="flex flex-col justify-center items-center">
                      <input
                        type="number"
                        value={vl1mul}
                        onChange={handleVl1mul}
                        min={0}
                        max={400}
                        className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                      />
                      <button
                        onClick={() => sendmsg(panelinfo.imei, vl1mulMessage)}
                        className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                      >
                        UPDATE
                      </button>
                    </div>
                  </td>
                </tr>
                {/* slot 2 */}
                <tr className="bg-violet-200">
                  <td className="border  p-2 font-bold text-center">VL2</td>
                  <td className="border  p-2 ">
                    <div className="flex justify-center items-center">
                      <div className="flex flex-col justify-center items-center">
                        <input
                          type="number"
                          value={vl2under}
                          onChange={handleVl2under}
                          min={0}
                          max={440}
                          className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                        />
                        <button
                          onClick={() =>
                            sendmsg(panelinfo.imei, vl2underMessage)
                          }
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                      <div className="flex flex-col justify-center items-center">
                        <label className="inline-flex items-center">
                          <input
                            type="number"
                            min={0}
                            max={1}
                            value={vl2ED}
                            onChange={handlevl2ED}
                            className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                          />
                        </label>
                        <button
                          onClick={() => sendmsg(panelinfo.imei, vl2EDmesaage)}
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                    </div>
                  </td>
                  <td className="border  p-2">
                    <div className="flex justify-center items-center">
                      <div className="flex flex-col justify-center items-center">
                        <input
                          type="number"
                          value={vl2over}
                          onChange={handleVl2over}
                          min={0}
                          max={440}
                          className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                        />
                        <button
                          onClick={() =>
                            sendmsg(panelinfo.imei, vl2overMessage)
                          }
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                      <div className="flex flex-col justify-center items-center">
                        <label className="inline-flex items-center">
                          <input
                            type="number"
                            min={0}
                            max={1}
                            value={vl2overED}
                            onChange={handlevl2overED}
                            className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                          />
                        </label>
                        <button
                          onClick={() =>
                            sendmsg(panelinfo.imei, vl2overEDmesaage)
                          }
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                    </div>
                  </td>
                  <td className="border  p-2 ">
                    <div className="flex justify-center items-center">
                      <div className="flex flex-col justify-center items-center">
                        <input
                          type="number"
                          value={vl2imb}
                          onChange={handleVl2imb}
                          min={0}
                          max={400}
                          className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                        />
                        <button
                          onClick={() => sendmsg(panelinfo.imei, vl2ImbMessage)}
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                      <div className="flex flex-col justify-center items-center">
                        <label className="inline-flex items-center">
                          <input
                            type="number"
                            min={0}
                            max={1}
                            value={vl2imbED}
                            onChange={handlevl2imbED}
                            className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                          />
                        </label>
                        <button
                          onClick={() =>
                            sendmsg(panelinfo.imei, vl2imbEDmesaage)
                          }
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                    </div>
                  </td>
                  <td className="border  p-2 ">
                    <div className="flex flex-col justify-center items-center">
                      <input
                        type="number"
                        value={vl2mul}
                        onChange={handleVl2mul}
                        min={0}
                        max={400}
                        className="border-gray-100 rounded-lg p-1 mx-2"
                      />
                      <button
                        onClick={() => sendmsg(panelinfo.imei, vl2mulMessage)}
                        className="btn bg-purple-600 text-white p-1  text-xs mx-2"
                      >
                        UPDATE
                      </button>
                    </div>
                  </td>
                </tr>
                {/* slot 3 */}
                <tr className="bg-purple-100">
                  <td className="border  p-2 font-bold text-center">VL3</td>
                  <td className="border  p-2 ">
                    <div className="flex justify-center items-center">
                      <div className="flex flex-col justify-center items-center">
                        <input
                          type="number"
                          value={vl3under}
                          min={0}
                          max={440}
                          onChange={handleVl3under}
                          className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                        />
                        <button
                          onClick={() =>
                            sendmsg(panelinfo.imei, vl3underMessage)
                          }
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                      <div className="flex flex-col justify-center items-center">
                        <label className="inline-flex items-center">
                          <input
                            type="number"
                            min={0}
                            max={1}
                            value={vl3ED}
                            onChange={handlevl3ED}
                            className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                          />
                        </label>
                        <button
                          onClick={() => sendmsg(panelinfo.imei, vl3EDmesaage)}
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                    </div>
                  </td>
                  <td className="border  p-2">
                    <div className="flex justify-center items-center">
                      <div className="flex flex-col justify-center items-center">
                        <input
                          type="number"
                          value={vl3over}
                          onChange={handleVl3over}
                          min={0}
                          max={440}
                          className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                        />
                        <button
                          onClick={() =>
                            sendmsg(panelinfo.imei, vl3overMessage)
                          }
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                      <div className="flex flex-col justify-center items-center">
                        <label className="inline-flex items-center">
                          <input
                            type="number"
                            min={0}
                            max={1}
                            value={vl3overED}
                            onChange={handlevl3overED}
                            className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                          />
                        </label>
                        <button
                          onClick={() =>
                            sendmsg(panelinfo.imei, vl3overEDmesaage)
                          }
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                    </div>
                  </td>
                  <td className="border  p-2 ">
                    <div className="flex justify-center items-center">
                      <div className="flex flex-col justify-center items-center">
                        <input
                          type="number"
                          value={vl3imb}
                          onChange={handleVl3imb}
                          min={0}
                          max={400}
                          className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                        />
                        <button
                          onClick={() => sendmsg(panelinfo.imei, vl3ImbMessage)}
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                      <div className="flex flex-col justify-center items-center">
                        <label className="inline-flex items-center">
                          <input
                            type="number"
                            min={0}
                            max={1}
                            value={vl3imbED}
                            onChange={handlevl3imbED}
                            className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                          />
                        </label>
                        <button
                          onClick={() =>
                            sendmsg(panelinfo.imei, vl3imbEDmesaage)
                          }
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                    </div>
                  </td>
                  <td className="border  p-2 ">
                    <div className="flex flex-col justify-center items-center">
                      <input
                        type="number"
                        value={vl3mul}
                        onChange={handleVl3mul}
                        min={0}
                        max={400}
                        className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                      />
                      <button
                        onClick={() => sendmsg(panelinfo.imei, vl3mulMessage)}
                        className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                      >
                        UPDATE
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* voltage config table */}

        <div className="text-center p-2 text-xl text-gray-200 font-bold font-sans">
          CURRENT CONFIGURATION
        </div>

        <div className="m-auto sm:mx-5 lg:mx-6 md:mx-6 xl:mx-8 2xl:mx-10">
          <div className="overflow-auto rounded-lg shadow p-5 bg-white m-1  bg-opacity-70 backdrop-filter backdrop-blur-lg font-sans">
            <table className="table-auto w-full mb-3">
              <thead className="border-b-2 border-gray-200">
                <tr>
                  <th className="border  p-2 bg-violet-400 text-gray-800  tracking-wide text-left text-sm font-semibold ">
                    Parameters
                  </th>
                  <th className="border  p-2 bg-violet-400 text-gray-800  tracking-wide text-left text-sm font-semibold ">
                    Under Current Limit
                  </th>
                  <th className="border  p-2 bg-violet-400 text-gray-800  tracking-wide text-left text-sm font-semibold ">
                    Over Current Limit
                  </th>
                  <th className="border  p-2 bg-violet-400 text-gray-800  tracking-wide text-left text-sm font-semibold ">
                    Imbalance current Limit
                  </th>
                  <th className="border  p-2 bg-violet-400 text-gray-800  tracking-wide text-left text-sm font-semibold ">
                    Zero Current Limit
                  </th>
                  <th className="border  p-2 bg-violet-400 text-gray-800  tracking-wide text-left text-sm font-semibold ">
                    Multiplier
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* slot 1 */}
                <tr className="bg-purple-100">
                  <td className="border p-2 font-bold text-center">IL1</td>
                  <td className="border  p-2 ">
                    <div className="flex justify-center items-center ">
                      <div className="flex flex-col justify-center items-center ">
                        <input
                          type="number"
                          value={il1under}
                          onChange={handleIl1under}
                          min={0}
                          max={440}
                          className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                        />
                        <button
                          onClick={() =>
                            sendmsg(panelinfo.imei, il1underMessage)
                          }
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                      <div className="flex flex-col justify-center items-center ">
                        <label className="inline-flex items-center">
                          <input
                            type="number"
                            min={0}
                            max={1}
                            value={il1ED}
                            onChange={handleil1ED}
                            className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                          />
                        </label>
                        <button
                          onClick={() => sendmsg(panelinfo.imei, il1EDmesaage)}
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                    </div>
                  </td>
                  <td className="border  p-2">
                    <div className="flex justify-center items-center">
                      <div className="flex flex-col justify-center items-center">
                        <input
                          type="number"
                          value={il1over}
                          onChange={handleIl1over}
                          min={0}
                          max={440}
                          className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                        />
                        <button
                          onClick={() =>
                            sendmsg(panelinfo.imei, il1overMessage)
                          }
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                      <div className="flex flex-col justify-center items-center">
                        <label className="inline-flex items-center">
                          <input
                            type="number"
                            min={0}
                            max={1}
                            value={il1overED}
                            onChange={handleil1overED}
                            className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                          />
                        </label>
                        <button
                          onClick={() =>
                            sendmsg(panelinfo.imei, il1overEDmesaage)
                          }
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                    </div>
                  </td>
                  <td className="border  p-2 ">
                    <div className="flex justify-center items-center">
                      <div className="flex flex-col justify-center items-center">
                        <input
                          type="number"
                          value={il1imb}
                          onChange={handleIl1imb}
                          min={0}
                          max={400}
                          className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                        />
                        <button
                          onClick={() => sendmsg(panelinfo.imei, il1ImbMessage)}
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                      <div className="flex flex-col justify-center items-center">
                        <label className="inline-flex items-center">
                          <input
                            type="number"
                            min={0}
                            max={1}
                            value={il1imbED}
                            onChange={handleil1imbED}
                            className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                          />
                        </label>
                        <button
                          onClick={() =>
                            sendmsg(panelinfo.imei, il1imbEDmesaage)
                          }
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                    </div>
                  </td>
                  {/* zero */}
                  <td className="border  p-2 ">
                    <div className="flex justify-center items-center">
                      <div className="flex flex-col justify-center items-center">
                        <input
                          type="number"
                          value={il1zero}
                          onChange={handleIl1zero}
                          min={0}
                          max={400}
                          className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                        />
                        <button
                          onClick={() =>
                            sendmsg(panelinfo.imei, il1zeroMessage)
                          }
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                      <div className="flex flex-col justify-center items-center">
                        <label className="inline-flex items-center">
                          <input
                            type="number"
                            min={0}
                            max={1}
                            value={il1zeroED}
                            onChange={handleil1zeroED}
                            className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                          />
                        </label>
                        <button
                          onClick={() =>
                            sendmsg(panelinfo.imei, il1zeroEDmesaage)
                          }
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                    </div>
                  </td>
                  <td className="border  p-2 ">
                    <div className="flex flex-col justify-center items-center">
                      <input
                        type="number"
                        value={il1mul}
                        onChange={handleIl1mul}
                        min={0}
                        max={400}
                        className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                      />
                      <button
                        onClick={() => sendmsg(panelinfo.imei, il1mulMessage)}
                        className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                      >
                        UPDATE
                      </button>
                    </div>
                  </td>
                </tr>
                {/* slot 2 */}
                <tr className="bg-violet-200">
                  <td className="border  p-2 font-bold text-center">IL2</td>
                  <td className="border  p-2 ">
                    <div className="flex justify-center items-center">
                      <div className="flex flex-col justify-center items-center">
                        <input
                          type="number"
                          value={il2under}
                          onChange={handleIl2under}
                          min={0}
                          max={440}
                          className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                        />
                        <button
                          onClick={() =>
                            sendmsg(panelinfo.imei, il2underMessage)
                          }
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                      <div className="flex flex-col justify-center items-center">
                        <label className="inline-flex items-center">
                          <input
                            type="number"
                            min={0}
                            max={1}
                            value={il2ED}
                            onChange={handleil2ED}
                            className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                          />
                        </label>
                        <button
                          onClick={() => sendmsg(panelinfo.imei, il2EDmesaage)}
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                    </div>
                  </td>
                  <td className="border  p-2">
                    <div className="flex justify-center items-center">
                      <div className="flex flex-col justify-center items-center">
                        <input
                          type="number"
                          value={il2over}
                          onChange={handleIl2over}
                          min={0}
                          max={440}
                          className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                        />
                        <button
                          onClick={() =>
                            sendmsg(panelinfo.imei, il2overMessage)
                          }
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                      <div className="flex flex-col justify-center items-center">
                        <label className="inline-flex items-center">
                          <input
                            type="number"
                            min={0}
                            max={1}
                            value={il2overED}
                            onChange={handleil2overED}
                            className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                          />
                        </label>
                        <button
                          onClick={() =>
                            sendmsg(panelinfo.imei, il2overEDmesaage)
                          }
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                    </div>
                  </td>
                  <td className="border  p-2 ">
                    <div className="flex justify-center items-center">
                      <div className="flex flex-col justify-center items-center">
                        <input
                          type="number"
                          value={il2imb}
                          onChange={handleIl2imb}
                          min={0}
                          max={400}
                          className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                        />
                        <button
                          onClick={() => sendmsg(panelinfo.imei, il2ImbMessage)}
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                      <div className="flex flex-col justify-center items-center">
                        <label className="inline-flex items-center">
                          <input
                            type="number"
                            min={0}
                            max={1}
                            value={il2imbED}
                            onChange={handleil2imbED}
                            className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                          />
                        </label>
                        <button
                          onClick={() =>
                            sendmsg(panelinfo.imei, il2imbEDmesaage)
                          }
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                    </div>
                  </td>
                  {/* zero */}
                  <td className="border  p-2 ">
                    <div className="flex justify-center items-center">
                      <div className="flex flex-col justify-center items-center">
                        <input
                          type="number"
                          value={il2zero}
                          onChange={handleIl2zero}
                          min={0}
                          max={400}
                          className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                        />
                        <button
                          onClick={() =>
                            sendmsg(panelinfo.imei, il2zeroMessage)
                          }
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                      <div className="flex flex-col justify-center items-center">
                        <label className="inline-flex items-center">
                          <input
                            type="number"
                            min={0}
                            max={1}
                            value={il2zeroED}
                            onChange={handleil2zeroED}
                            className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                          />
                        </label>
                        <button
                          onClick={() =>
                            sendmsg(panelinfo.imei, il2zeroEDmesaage)
                          }
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                    </div>
                  </td>
                  <td className="border  p-2 ">
                    <div className="flex flex-col justify-center items-center">
                      <input
                        type="number"
                        value={il2mul}
                        onChange={handleIl2mul}
                        min={0}
                        max={400}
                        className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                      />
                      <button
                        onClick={() => sendmsg(panelinfo.imei, il2mulMessage)}
                        className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                      >
                        UPDATE
                      </button>
                    </div>
                  </td>
                </tr>
                {/* slot 3 */}
                <tr className="bg-purple-100">
                  <td className="border  p-2 font-bold text-center">IL3</td>
                  <td className="border  p-2">
                    <div className="flex justify-center items-center">
                      <div className="flex flex-col justify-center items-center">
                        <input
                          type="number"
                          value={il3under}
                          min={0}
                          max={440}
                          onChange={handleIl3under}
                          className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                        />
                        <button
                          onClick={() =>
                            sendmsg(panelinfo.imei, il3underMessage)
                          }
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                      <div className="flex flex-col justify-center items-center">
                        <label className="inline-flex items-center">
                          <input
                            type="number"
                            min={0}
                            max={1}
                            value={il3ED}
                            onChange={handleil3ED}
                            className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                          />
                        </label>
                        <button
                          onClick={() => sendmsg(panelinfo.imei, il3EDmesaage)}
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                    </div>
                  </td>
                  <td className="border  p-2 ">
                    <div className="flex justify-center items-center">
                      <div className="flex  flex-col justify-center items-center">
                        <input
                          type="number"
                          value={il3over}
                          onChange={handleIl3over}
                          min={0}
                          max={440}
                          className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                        />
                        <button
                          onClick={() =>
                            sendmsg(panelinfo.imei, il3overMessage)
                          }
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                      <div className="flex  flex-col justify-center items-center">
                        <label className="inline-flex items-center">
                          <input
                            type="number"
                            min={0}
                            max={1}
                            value={il3overED}
                            onChange={handleil3overED}
                            className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                          />
                        </label>
                        <button
                          onClick={() =>
                            sendmsg(panelinfo.imei, il3overEDmesaage)
                          }
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                    </div>
                  </td>
                  <td className="border  p-2 ">
                    <div className="flex justify-center items-center">
                      <div className="flex  flex-col justify-center items-center">
                        <input
                          type="number"
                          value={il3imb}
                          onChange={handleIl3imb}
                          min={0}
                          max={400}
                          className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                        />
                        <button
                          onClick={() => sendmsg(panelinfo.imei, il3ImbMessage)}
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                      <div className="flex  flex-col justify-center items-center">
                        <label className="inline-flex items-center">
                          <input
                            type="number"
                            min={0}
                            max={1}
                            value={il3imbED}
                            onChange={handleil3imbED}
                            className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                          />
                        </label>
                        <button
                          onClick={() =>
                            sendmsg(panelinfo.imei, il3imbEDmesaage)
                          }
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                    </div>
                  </td>
                  {/* zero */}
                  <td className="border  p-2  ">
                    <div className="flex justify-center items-center">
                      <div className="flex flex-col justify-center items-center">
                        <input
                          type="number"
                          value={il3zero}
                          onChange={handleIl3zero}
                          min={0}
                          max={400}
                          className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                        />
                        <button
                          onClick={() =>
                            sendmsg(panelinfo.imei, il3zeroMessage)
                          }
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                      <div className="flex flex-col justify-center items-center">
                        <label className="inline-flex items-center">
                          <input
                            type="number"
                            min={0}
                            max={1}
                            value={il3zeroED}
                            onChange={handleil3zeroED}
                            className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                          />
                        </label>
                        <button
                          onClick={() =>
                            sendmsg(panelinfo.imei, il3zeroEDmesaage)
                          }
                          className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                        >
                          UPDATE
                        </button>
                      </div>
                    </div>
                  </td>
                  <td className="border  p-2 ">
                    <div className="flex flex-col justify-center items-center">
                      <input
                        type="number"
                        value={il3mul}
                        onChange={handleIl3mul}
                        min={0}
                        max={400}
                        className="border-gray-100 rounded-lg p-1 mx-2 my-1"
                      />
                      <button
                        onClick={() => sendmsg(panelinfo.imei, il3mulMessage)}
                        className="btn bg-purple-600 text-white p-1  text-xs mx-2 my-1"
                      >
                        UPDATE
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="text-center p-2 text-xl text-gray-200 font-bold font-sans">
          TIMER CONFIGURATION
        </div>

        <div className="m-auto sm:mx-5 lg:mx-6 md:mx-6 xl:mx-8 2xl:mx-10 ">
          <div className="overflow-auto rounded-lg shadow p-5 bg-white m-1  bg-opacity-70 backdrop-filter backdrop-blur-lg font-sans">
            <table className="table-auto w-full mb-3">
              <thead className="border-b-2 border-gray-200">
                <tr>
                  <th className="border  p-2 bg-violet-400 text-gray-800  tracking-wide text-left text-sm font-semibold ">
                    Parameters
                  </th>
                  <th className="border  p-2 bg-violet-400 text-gray-800  tracking-wide text-left text-sm font-semibold ">
                    Start Time
                  </th>
                  <th className="border  p-2 bg-violet-400 text-gray-800  tracking-wide text-left text-sm font-semibold ">
                    Running Minutes
                  </th>
                  <th className="border  p-2 bg-violet-400 text-gray-800  tracking-wide text-left text-sm font-semibold ">
                    Enable/Disable
                  </th>
                </tr>
              </thead>

              <tbody>
                {/* slot 1 */}
                <tr className="bg-purple-100">
                  <td className="border p-2 font-bold text-center">SLOT 1</td>
                  <td className="border  p-2 ">
                    <input
                      type="time"
                      value={slot1startT}
                      onChange={handleslot1StartT}
                      className="border-gray-100 rounded-lg p-1 mx-2"
                    />
                    <button
                      onClick={() =>
                        sendmsg(panelinfo.imei, slot1startTMessage)
                      }
                      className="btn bg-purple-600 text-white p-1  text-xs mx-2"
                    >
                      UPDATE
                    </button>
                  </td>
                  <td className="border  p-2">
                    <input
                      type="number"
                      value={slot1runTime}
                      onChange={handleslot1RunTime}
                      className="border-gray-100 rounded-lg p-1 mx-2 w-1/2"
                    />
                    <button
                      onClick={() =>
                        sendmsg(panelinfo.imei, slot1runTimeMessage)
                      }
                      className="btn bg-purple-600 text-white p-1  text-xs mx-2"
                    >
                      UPDATE
                    </button>
                  </td>
                  <td className="border  p-2">
                    <label className="inline-flex items-center">
                      <input
                        type="number"
                        className="border-gray-100 rounded-lg p-1 mx-2 "
                        min={0}
                        max={1}
                        value={slot1ED}
                        onChange={handleslot1ED}
                      />
                      <span className="ml-2">
                        {slot1ED === 1 ? "Enabled" : "Disabled"}
                      </span>
                    </label>
                    <button
                      onClick={() => sendmsg(panelinfo.imei, slot1EDMessage)}
                      className="btn bg-purple-600 text-white p-1  text-xs mx-2"
                    >
                      UPDATE
                    </button>
                  </td>
                </tr>
                {/* slot 2 */}
                <tr className="bg-violet-200">
                  <td className="border  p-2 font-bold text-center">SLOT 2</td>
                  <td className="border  p-2 ">
                    <input
                      type="time"
                      value={slot2startT}
                      onChange={handleslot2StartT}
                      className="border-gray-100 rounded-lg p-1 mx-2"
                    />
                    <button
                      onClick={() =>
                        sendmsg(panelinfo.imei, slot2startTMessage)
                      }
                      className="btn bg-purple-600 text-white p-1  text-xs mx-2"
                    >
                      UPDATE
                    </button>
                  </td>
                  <td className="border  p-2">
                    <input
                      type="number"
                      value={slot2runTime}
                      onChange={handleslot2RunTime}
                      className="border-gray-100 rounded-lg p-1 mx-2 w-1/2"
                    />
                    <button
                      onClick={() =>
                        sendmsg(panelinfo.imei, slot2runTimeMessage)
                      }
                      className="btn bg-purple-600 text-white p-1  text-xs mx-2"
                    >
                      UPDATE
                    </button>
                  </td>
                  <td className="border  p-2">
                    <label className="inline-flex items-center">
                      <input
                        type="number"
                        className="border-gray-100 rounded-lg p-1 mx-2"
                        value={slot2ED}
                        onChange={handleslot2ED}
                        min={0}
                        max={1}
                      />
                      <span className="ml-2">
                        {slot2ED === 1 ? "Enabled" : "Disabled"}
                      </span>
                    </label>
                    <button
                      onClick={() => sendmsg(panelinfo.imei, slot2EDMessage)}
                      className="btn bg-purple-600 text-white p-1  text-xs mx-2"
                    >
                      UPDATE
                    </button>
                  </td>
                </tr>
                {/* slot 3 */}
                <tr className="bg-purple-100">
                  <td className="border  p-2 font-bold text-center">SLOT 3</td>
                  <td className="border p-2 ">
                    <input
                      type="time"
                      value={slot3startT}
                      onChange={handleslot3StartT}
                      className="border-gray-100 rounded-lg p-1 mx-2"
                    />
                    <button
                      onClick={() =>
                        sendmsg(panelinfo.imei, slot3startTMessage)
                      }
                      className="btn bg-purple-600 text-white p-1  text-xs mx-2"
                    >
                      UPDATE
                    </button>
                  </td>
                  <td className="border  p-2">
                    <input
                      type="number"
                      value={slot3runTime}
                      onChange={handleslot3RunTime}
                      className="border-gray-100 rounded-lg p-1 mx-2 w-1/2"
                    />
                    <button
                      onClick={() =>
                        sendmsg(panelinfo.imei, slot3runTimeMessage)
                      }
                      className="btn bg-purple-600 text-white p-1  text-xs mx-2"
                    >
                      UPDATE
                    </button>
                  </td>
                  <td className="border  p-2">
                    <label className="inline-flex items-center">
                      <input
                        type="number"
                        className="border-gray-100 rounded-lg p-1 mx-2"
                        min={0}
                        max={1}
                        value={slot3ED}
                        onChange={handleslot3ED}
                      />
                      <span className="ml-2">
                        {slot3ED === 1 ? "Enabled" : "Disabled"}
                      </span>
                    </label>
                    <button
                      onClick={() => sendmsg(panelinfo.imei, slot3EDMessage)}
                      className="btn bg-purple-600 text-white p-1  text-xs mx-2"
                    >
                      UPDATE
                    </button>
                  </td>
                </tr>
                {/* slot 4 */}
                <tr className="bg-violet-200">
                  <td className="border  p-2 font-bold text-center">SLOT 4</td>
                  <td className="border  p-2 ">
                    <input
                      type="time"
                      value={slot4startT}
                      onChange={handleslot4StartT}
                      className="border-gray-100 rounded-lg p-1 mx-2"
                    />
                    <button
                      onClick={() =>
                        sendmsg(panelinfo.imei, slot4startTMessage)
                      }
                      className="btn bg-purple-600 text-white p-1  text-xs mx-2"
                    >
                      UPDATE
                    </button>
                  </td>
                  <td className="border  p-2">
                    <input
                      type="number"
                      value={slot4runTime}
                      onChange={handleslot4RunTime}
                      className="border-gray-100 rounded-lg p-1 mx-2 w-1/2"
                    />
                    <button
                      onClick={() =>
                        sendmsg(panelinfo.imei, slot4runTimeMessage)
                      }
                      className="btn bg-purple-600 text-white p-1  text-xs mx-2"
                    >
                      UPDATE
                    </button>
                  </td>
                  <td className="border  p-2">
                    <label className="inline-flex items-center">
                      <input
                        type="number"
                        className="border-gray-100 rounded-lg p-1 mx-2"
                        min={0}
                        max={1}
                        value={slot4ED}
                        onChange={handleslot4ED}
                      />
                      <span className="ml-2">
                        {slot4ED === 1 ? "Enabled" : "Disabled"}
                      </span>
                    </label>
                    <button
                      onClick={() => sendmsg(panelinfo.imei, slot4EDMessage)}
                      className="btn bg-purple-600 text-white p-1  text-xs mx-2"
                    >
                      UPDATE
                    </button>
                  </td>
                </tr>
                {/* slot 5 */}
                <tr className="bg-purple-100">
                  <td className="border  p-2 font-bold text-center">SLOT 5</td>
                  <td className="border  p-2 ">
                    <input
                      type="time"
                      value={slot5startT}
                      onChange={handleslot5StartT}
                      className="border-gray-100 rounded-lg p-1 mx-2"
                    />
                    <button
                      onClick={() =>
                        sendmsg(panelinfo.imei, slot5startTMessage)
                      }
                      className="btn bg-purple-600 text-white p-1  text-xs mx-2"
                    >
                      UPDATE
                    </button>
                  </td>
                  <td className="border  p-2">
                    <input
                      type="number"
                      value={slot5runTime}
                      onChange={handleslot5RunTime}
                      className="border-gray-100 rounded-lg p-1 mx-2 w-1/2"
                    />
                    <button
                      onClick={() =>
                        sendmsg(panelinfo.imei, slot5runTimeMessage)
                      }
                      className="btn bg-purple-600 text-white p-1  text-xs mx-2"
                    >
                      UPDATE
                    </button>
                  </td>
                  <td className="border  p-2">
                    <label className="inline-flex items-center">
                      <input
                        type="number"
                        className="border-gray-100 rounded-lg p-1 mx-2"
                        min={0}
                        max={1}
                        value={slot5ED}
                        onChange={handleslot5ED}
                      />
                      <span className="ml-2">
                        {slot5ED === 1 ? "Enabled" : "Disabled"}
                      </span>
                    </label>
                    <button
                      onClick={() => sendmsg(panelinfo.imei, slot5EDMessage)}
                      className="btn bg-purple-600 text-white p-1  text-xs mx-2"
                    >
                      UPDATE
                    </button>
                  </td>
                </tr>
                {/* slot 6 */}
                <tr className="bg-violet-200">
                  <td className="border  p-2 font-bold text-center">SLOT 6</td>
                  <td className="border  p-2 ">
                    <input
                      type="time"
                      value={slot6startT}
                      onChange={handleslot6StartT}
                      className="border-gray-100 rounded-lg p-1 mx-2"
                    />
                    <button
                      onClick={() =>
                        sendmsg(panelinfo.imei, slot6startTMessage)
                      }
                      className="btn bg-purple-600 text-white p-1  text-xs mx-2"
                    >
                      UPDATE
                    </button>
                  </td>
                  <td className="border  p-2">
                    <input
                      type="number"
                      value={slot6runTime}
                      onChange={handleslot6RunTime}
                      className="border-gray-100 rounded-lg p-1 mx-2 w-1/2"
                    />
                    <button
                      onClick={() =>
                        sendmsg(panelinfo.imei, slot6runTimeMessage)
                      }
                      className="btn bg-purple-600 text-white p-1  text-xs mx-2"
                    >
                      UPDATE
                    </button>
                  </td>
                  <td className="border  p-2">
                    <label className="inline-flex items-center">
                      <input
                        type="number"
                        className="border-gray-100 rounded-lg p-1 mx-2"
                        min={0}
                        max={1}
                        value={slot6ED}
                        onChange={handleslot6ED}
                      />
                      <span className="ml-2">
                        {slot6ED === 1 ? "Enabled" : "Disabled"}
                      </span>
                    </label>
                    <button
                      onClick={() => sendmsg(panelinfo.imei, slot6EDMessage)}
                      className="btn bg-purple-600 text-white p-1  text-xs mx-2"
                    >
                      UPDATE
                    </button>
                  </td>
                </tr>
                {/* slot 7 */}
                <tr className="bg-purple-100">
                  <td className="border  p-2 font-bold text-center">SLOT 7</td>
                  <td className="border  p-2 ">
                    <input
                      type="time"
                      value={slot7startT}
                      onChange={handleslot7StartT}
                      className="border-gray-100 rounded-lg p-1 mx-2"
                    />
                    <button
                      onClick={() =>
                        sendmsg(panelinfo.imei, slot7startTMessage)
                      }
                      className="btn bg-purple-600 text-white p-1  text-xs mx-2"
                    >
                      UPDATE
                    </button>
                  </td>
                  <td className="border  p-2">
                    <input
                      type="number"
                      value={slot7runTime}
                      onChange={handleslot7RunTime}
                      className="border-gray-100 rounded-lg p-1 mx-2 w-1/2"
                    />
                    <button
                      onClick={() =>
                        sendmsg(panelinfo.imei, slot7runTimeMessage)
                      }
                      className="btn bg-purple-600 text-white p-1  text-xs mx-2"
                    >
                      UPDATE
                    </button>
                  </td>
                  <td className="border  p-2">
                    <label className="inline-flex items-center">
                      <input
                        type="number"
                        className="border-gray-100 rounded-lg p-1 mx-2"
                        min={0}
                        max={1}
                        value={slot7ED}
                        onChange={handleslot7ED}
                      />
                      <span className="ml-2">
                        {slot7ED === 1 ? "Enabled" : "Disabled"}
                      </span>
                    </label>
                    <button
                      onClick={() => sendmsg(panelinfo.imei, slot7EDMessage)}
                      className="btn bg-purple-600 text-white p-1  text-xs mx-2"
                    >
                      UPDATE
                    </button>
                  </td>
                </tr>
                {/* slot 8 */}
                <tr className="bg-violet-200">
                  <td className="border  p-2 font-bold text-center">SLOT 8</td>
                  <td className="border  p-2 ">
                    <input
                      type="time"
                      value={slot8startT}
                      onChange={handleslot8StartT}
                      className="border-gray-100 rounded-lg p-1 mx-2"
                    />
                    <button
                      onClick={() =>
                        sendmsg(panelinfo.imei, slot8startTMessage)
                      }
                      className="btn bg-purple-600 text-white p-1  text-xs mx-2"
                    >
                      UPDATE
                    </button>
                  </td>
                  <td className="border  p-2">
                    <input
                      type="number"
                      value={slot8runTime}
                      onChange={handleslot8RunTime}
                      className="border-gray-100 rounded-lg p-1 mx-2 w-1/2"
                    />
                    <button
                      onClick={() =>
                        sendmsg(panelinfo.imei, slot8runTimeMessage)
                      }
                      className="btn bg-purple-600 text-white p-1  text-xs mx-2"
                    >
                      UPDATE
                    </button>
                  </td>
                  <td className="border  p-2">
                    <label className="inline-flex items-center">
                      <input
                        type="number"
                        className="border-gray-100 rounded-lg p-1 mx-2"
                        min={0}
                        max={1}
                        value={slot8ED}
                        onChange={handleslot8ED}
                      />
                      <span className="ml-2">
                        {slot8ED === 1 ? "Enabled" : "Disabled"}
                      </span>
                    </label>
                    <button
                      onClick={() => sendmsg(panelinfo.imei, slot8EDMessage)}
                      className="btn bg-purple-600 text-white p-1  text-xs mx-2"
                    >
                      UPDATE
                    </button>
                  </td>
                </tr>
                {/* slot 9 */}
                <tr className="bg-purple-100">
                  <td className="border  p-2 font-bold text-center">SLOT 9</td>
                  <td className="border  p-2 ">
                    <input
                      type="time"
                      value={slot9startT}
                      onChange={handleslot9StartT}
                      className="border-gray-100 rounded-lg p-1 mx-2"
                    />
                    <button
                      onClick={() =>
                        sendmsg(panelinfo.imei, slot9startTMessage)
                      }
                      className="btn bg-purple-600 text-white p-1  text-xs mx-2"
                    >
                      UPDATE
                    </button>
                  </td>
                  <td className="border  p-2">
                    <input
                      type="number"
                      value={slot9runTime}
                      onChange={handleslot9RunTime}
                      className="border-gray-100 rounded-lg p-1 mx-2 w-1/2"
                    />
                    <button
                      onClick={() =>
                        sendmsg(panelinfo.imei, slot9runTimeMessage)
                      }
                      className="btn bg-purple-600 text-white p-1  text-xs mx-2"
                    >
                      UPDATE
                    </button>
                  </td>
                  <td className="border  p-2">
                    <label className="inline-flex items-center">
                      <input
                        type="number"
                        className="border-gray-100 rounded-lg p-1 mx-2"
                        min={0}
                        max={1}
                        value={slot9ED}
                        onChange={handleslot9ED}
                      />
                      <span className="ml-2">
                        {slot9ED === 1 ? "Enabled" : "Disabled"}
                      </span>
                    </label>
                    <button
                      onClick={() => sendmsg(panelinfo.imei, slot9EDMessage)}
                      className="btn bg-purple-600 text-white p-1  text-xs mx-2"
                    >
                      UPDATE
                    </button>
                  </td>
                </tr>
                {/* slot 10 */}
                <tr className="bg-violet-200">
                  <td className="border  p-2 font-bold text-center">SLOT 10</td>
                  <td className="border  p-2 ">
                    <input
                      type="time"
                      value={slot10startT}
                      onChange={handleslot10StartT}
                      className="border-gray-100 rounded-lg p-1 mx-2"
                    />
                    <button
                      onClick={() =>
                        sendmsg(panelinfo.imei, slot10startTMessage)
                      }
                      className="btn bg-purple-600 text-white p-1  text-xs mx-2"
                    >
                      UPDATE
                    </button>
                  </td>
                  <td className="border  p-2">
                    <input
                      type="number"
                      value={slot10runTime}
                      onChange={handleslot10RunTime}
                      className="border-gray-100 rounded-lg p-1 mx-2 w-1/2"
                    />
                    <button
                      onClick={() =>
                        sendmsg(panelinfo.imei, slot10runTimeMessage)
                      }
                      className="btn bg-purple-600 text-white p-1  text-xs mx-2"
                    >
                      UPDATE
                    </button>
                  </td>
                  <td className="border  p-2">
                    <label className="inline-flex items-center">
                      <input
                        type="number"
                        className="border-gray-100 rounded-lg p-1 mx-2"
                        min={0}
                        max={1}
                        value={slot10ED}
                        onChange={handleslot10ED}
                      />
                      <span className="ml-2">
                        {slot10ED === 1 ? "Enabled" : "Disabled"}
                      </span>
                    </label>
                    <button
                      onClick={() => sendmsg(panelinfo.imei, slot10EDMessage)}
                      className="btn bg-purple-600 text-white p-1  text-xs mx-2"
                    >
                      UPDATE
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="p-5"></div>
      </div>
    </div>
  );
};

export default Table;
