import React from "react";

const FuelContainer = ({ fuelLevel }) => {
  const containerHeight = 100; // Height of the container is set to 100
  const filledHeight = (fuelLevel / 100) * containerHeight; // Calculate the filled height based on the fuel level

  return (
    <div className="relative w-32 sm:w-32 md:w-32 lg:w-32 h-32 sm:h-32 bg-gray-100  border-4 border-gray-300 rounded-lg p-2 overflow-hidden">
      <div className="">
        <div
          className={`absolute bottom-0 left-0 w-full rounded-lg ${
            filledHeight < 30 ? "bg-red-600" : "bg-green-600"
          } flex flex-col align-middle items-center justify-center`}
          style={{ height: `${filledHeight}%` }}
        >
          <div className="text-black font-bold text-lg sm:text-xl md:text-xl lg:text-xl">
            {filledHeight}%
          </div>
        </div>
      </div>
    </div>
  );
};

export default FuelContainer;
