import React from "react";
import { Pie } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js/auto";
// import { pfinal, tfinal } from "./Container";

Chart.register(CategoryScale);

const PieChart = ({
  value1Label,
  value2Label,
  value3Label,
  tag,
  value1,
  value2,
  value3,
}) => {
  return (
    <div className="bg-gray-100 rounded-lg py-5">
      <div className="flex flex-col sm:flex-row justify-center items-center">
        <div className="w-full h-full sm:h-1/2 md:h-1/2 lg:h-1/2 xl:h-full sm:w-1/2 md:w-full lg:w-full xl:w-full">
          <Pie
            data={{
              labels: [value1Label, value2Label, value3Label],
              //   labels: [1, 2, 3, 4, 5],

              datasets: [
                {
                  label: tag,
                  //   backgroundColor: color,
                  backgroundColor: [
                    "rgba(255,0,0,0.8)",
                    "rgba(255,215,0,0.8)",
                    "rgba(0,0,139,0.8)",
                  ],
                  // backgroundColor: [""],
                  // hoverBackgroundColor: color.map(
                  //   (c) => `${c}D9` // lighter version of color for hover state
                  // ),

                  // borderColor: colour,
                  // borderDash: [5, 1],
                  borderWidth: 1,

                  data: [value1, value2, value3],
                },
              ],
            }}
            options={{
              elements: {
                line: {
                  tension: 0, // makes the line straight
                  fill: {
                    target: "origin",
                    above: "rgba(147,112,219,0.3)", // color of shadow above the line
                  },
                },
              },
              maintainAspectRatio: false,
              responsive: true,
              scales: {
                x: {
                  type: "category",
                  offset: false,
                  ticks: {
                    autoSkip: false,
                    maxRotation: 60,
                    minRotation: 60,
                    font: {
                      size: 0,
                      weight: "bold",
                    },
                  },
                  grid: {
                    display: false,
                    color: "rgba(0, 0, 0, 0.1)",
                    borderWidth: 1,
                    borderColor: "rgba(0,0, 0.1)",
                  },
                  radius: 70,
                },
              },

              plugins: {
                legend: {
                  display: true,
                  position: "left",
                  labels: {
                    font: {
                      size: 10,
                      weight: "bold",
                    },
                  },
                  maxWidth: 150,
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PieChart;
