import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Transition from "../../utils/Transition";

import UserAvatar from "../../images/white.png";

import { useNavigate } from "react-router-dom";

function UserMenu() {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const disconnectString = localStorage.getItem("disconnect");
  const disconnect = eval("(" + disconnectString + ")");

  // console.log(disconnect());

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  // const history = useNavigate();
  const goBack = () => {
    window.history.back();
  };

  return (
    <div className="relative inline-flex justify-center items-center">
      <button
        ref={trigger}
        className="inline-flex items-center justify-center group font-bold"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <img
          className=" text-white rounded-lg"
          src="/images/SRTLOGO.png"
          width="30"
          height="30"
          alt="User"
        />
        <div className="flex items-center truncate">
          <span className="ml-2 text-lg font-medium truncate text-[#58534c] ">
            SRT{" "}
          </span>
          <svg
            className="w-3 h-3 ml-1 fill-current shrink-0 text-slate-400"
            viewBox="0 0 12 12"
          >
            <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
          </svg>
        </div>
      </button>

      <Transition
        className="origin-top-right z-10 absolute top-full right-0 min-w-44 bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-slate-200">
            <div className="font-medium text-slate-800">SRT.</div>
            <div className="text-xs italic text-slate-500">Administrator</div>
          </div>
          <ul>
            <li>
              <Link
                to="/Home"
                className="flex items-center px-3 py-1 text-sm font-medium text-[#e68d26] hover:text-[#865114]"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                Home
              </Link>
            </li>
            {/* <li>
              <Link
                to="/AlertTable"
                // to="/Home"
                className="flex items-center px-3 py-1 text-sm font-medium text-[#e68d26] hover:text-[#865114]"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                Notification
              </Link>
            </li> */}
            {/* <li>
              <Link
                to="/Table"
                className="flex items-center px-3 py-1 text-sm font-medium text-[#e68d26] hover:text-[#865114]"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                Configuration
              </Link>
            </li> */}
            <li>
              <div>
                <button
                  className="flex items-center px-3 py-1 text-sm font-medium text-[#e68d26] hover:text-[#865114]"
                  onClick={() => {
                    setDropdownOpen(!dropdownOpen);
                    goBack();
                  }}
                >
                  Go Back
                </button>
              </div>
            </li>
            <li>
              <Link
                className="flex items-center px-3 py-1 text-sm font-medium text-[#e68d26] hover:text-[#865114]"
                to="/"
                onClick={() => {
                  disconnect();
                  const sendfunc = localStorage.sendmsg;
                  localStorage.clear();
                  localStorage.sendmsg = sendfunc;
                  setDropdownOpen(!dropdownOpen);
                }}
              >
                Log Out
              </Link>
            </li>
          </ul>
        </div>
      </Transition>
    </div>
  );
}

export default UserMenu;
