import React, { useEffect } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useState } from "react";
// import UserMenu from "./UserMenu";

const Home = () => {
  const cards = [];
  const options = [];

  let [mqttdata, setMqttData] = useState([]);
  mqttdata = JSON.parse(localStorage.getItem("mqttdata"));

  setInterval(() => {
    // useEffect(() => {
    let mqttdata = JSON.parse(localStorage.getItem("mqttdata"));
    setMqttData(mqttdata);
    // }, [mqttdata]);
  }, 2000);

  // console.log(mqttdata);

  let imeilist = JSON.parse(localStorage.getItem("imeilist"));
  // console.log(imeilist);

  let device_ids = JSON.parse(localStorage.getItem("device_ids"));
  // console.log(device_ids);
  let namelist = JSON.parse(localStorage.getItem("namelist"));
  let location_list = JSON.parse(localStorage.getItem("location_list"));

  function handleClick(imei, deviceid, cardid) {
    const data = { imei, deviceid, cardid };
    localStorage.setItem("data", JSON.stringify(data));
  }

  for (let i = 0; i < imeilist.length; i++) {
    options.push({
      value: i,
      label: `Device ${i + 1}`,
    });
    cards.push(
      //   <Link to="/Dashboard">
      <div
        key={i}
        id={`card${i}`}
        className=" w-auto h-auto sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/5 sm:h-1/3 md:h-1/3 lg:h-1/3 xl:h-1/5 2xl:h-1/4 2xl:w-1/6 3xl:h-1/4 3xl:w-1/6 p-5 mt-2 shadow-gray-700 ease-in hover:shadow-4xl transition-shadow hover:shadow-stone-900 
    max-w-sm max-h-full rounded-lg overflow-hidden shadow-lg m-2 cursor-pointer flex-grow bg-[#F5E393]  backdrop-filter backdrop-blur-lg "
      >
        <div className="border-b-4 border-[#e68d26] mb-2">
          <Link
            to="/Dashboard"
            onClick={() => handleClick(imeilist[i], device_ids[i], i)}
          >
            <h2 className="text-center font-semibold font-mono text-xl pb-3 text-[#58534c]">
              Location: {location_list[i]}
            </h2>
            {/* <h2 className="text-center font-semibold font-mono text-lg pb-3 text-[#58534c]">
              {namelist[i]}
            </h2> */}
            <h3 className="text-center font-semibold font-mono text-sm pb-3 text-[#58534c]">
              ({imeilist[i]})
            </h3>
          </Link>
        </div>

        <div className="flex flex-wrap justify-center items-center">
          <p className="p-0.5 m-1 bg-gray-100 rounded-lg shadow-xl transition duration-300 ease-in-out focus:outline-none block text-[#58534c] font-bold text-center flex-1 text-sm ">
            VL<sub>1</sub>
            <p className="p-0.5 m-1 bg-red-400 hover:bg-red-600 hover:text-white rounded-lg shadow-lg hover:shadow-indigo-500/30 border border-red-500 hover:border-red-900 transition duration-300 ease-in-out focus:outline-none block text-[#353330] font-bold text-sm ">
              {mqttdata?.[i] ? mqttdata[i][15] * 0.1 : 0} V
            </p>
          </p>
          <p className="p-0.5 m-1 bg-gray-100 rounded-lg shadow-xl transition duration-300 ease-in-out focus:outline-none block text-[#58534c] font-bold text-center flex-1 text-sm">
            VL<sub>2</sub>{" "}
            <p className="p-0.5 m-1 bg-yellow-300 hover:bg-yellow-400 hover:text-black rounded-lg shadow-xl border border-yellow-500 hover:border-yellow-500 transition duration-300 ease-in-out focus:outline-none block text-[#353330] font-bold text-sm ">
              {mqttdata?.[i] ? mqttdata[i][16] * 0.1 : 0} V
            </p>
          </p>
          <p className="p-0.5 m-1 bg-gray-100 rounded-lg shadow-xl transition duration-300 ease-in-out focus:outline-none block text-[#58534c] font-bold text-center flex-1 text-sm">
            VL<sub>3</sub>{" "}
            <p className="p-0.5 m-1 bg-blue-400 hover:bg-blue-700 hover:text-white rounded-lg shadow-xl border border-indigo-500 hover:border-blue-900 transition duration-300 ease-in-out focus:outline-none block text-[#353330] font-bold text-sm">
              {mqttdata?.[i] ? mqttdata[i][17] * 0.1 : 0} V
            </p>
          </p>
        </div>

        <div className="flex flex-wrap justify-center items-center ">
          <p className="p-0.5 m-1 bg-gray-100 rounded-lg shadow-xl transition duration-300 ease-in-out focus:outline-none block text-[#58534c] font-bold text-center flex-1 text-sm">
            IL<sub>1</sub>{" "}
            <p className="p-0.5 m-1 bg-red-400 hover:bg-red-600 hover:text-white rounded-lg shadow-xl border border-red-500 hover:border-red-900 transition duration-300 ease-in-out focus:outline-none block text-[#353330] font-bold text-sm">
              {mqttdata?.[i] ? mqttdata[i][21] * 0.1 : 0} A
            </p>
          </p>
          <p className="p-0.5 m-1 bg-gray-100 rounded-lg shadow-xl transition duration-300 ease-in-out focus:outline-none block text-[#58534c] font-bold text-center flex-1 text-sm">
            IL<sub>2</sub>{" "}
            <p className="p-0.5 m-1 bg-yellow-300 hover:bg-yellow-400 hover:text-black rounded-lg shadow-xl border border-yellow-500 hover:border-yellow-500 transition duration-300 ease-in-out focus:outline-none block text-[#353330] font-bold text-sm">
              {mqttdata?.[i] ? mqttdata[i][22] * 0.1 : 0} A
            </p>
          </p>
          <p className="p-0.5 m-1 bg-gray-100 rounded-lg shadow-xl transition duration-300 ease-in-out focus:outline-none block text-[#58534c] font-bold text-center flex-1 text-sm">
            IL<sub>3</sub>{" "}
            <p className="p-0.5 m-1 bg-blue-400 hover:bg-blue-700 hover:text-white rounded-lg shadow-xl border border-blue-500 hover:border-blue-900 transition duration-300 ease-in-out focus:outline-none block text-[#353330] font-bold text-sm ">
              {mqttdata?.[i] ? mqttdata[i][23] * 0.1 : 0} A
            </p>
          </p>
        </div>

        <div className="flex flex-wrap">
          <p className="p-0.5 m-1 bg-gray-100 rounded-lg shadow-xl transition duration-300 ease-in-out focus:outline-none block text-[#58534c] font-bold text-center flex-1 text-xs">
            FUEL LEVEL{" "}
            <p className="p-0.5 m-1 bg-[#F2971E] rounded-lg shadow-xl border  transition duration-300 ease-in-out focus:outline-none block text-[#353330] font-bold text-sm">
              {mqttdata?.[i] ? mqttdata[i][4] : 0} %
            </p>
          </p>
          <p className="p-0.5 m-1 bg-gray-100 rounded-lg shadow-xl transition duration-300 ease-in-out focus:outline-none block text-[#58534c] font-bold text-center flex-1 text-xs">
            COOLANT TEMP{" "}
            <p className="p-0.5 m-1 bg-[#F2971E] rounded-lg shadow-xl border  transition duration-300 ease-in-out focus:outline-none block text-[#353330] font-bold text-sm">
              {mqttdata?.[i] ? mqttdata[i][3] * 0.1 : 0} °C
            </p>
          </p>
        </div>

        <div className="flex flex-wrap">
          <p
            className={`flex flex-col items-center flex-1 p-1 m-1 justify-center  text-[#58534c] font-bold text-center text-sm  bg-gray-100 rounded-lg shadow-xl transition duration-300 ease-in-out focus:outline-none  `}
          >
            ENGINE STATUS
            <button
              className={`flex items-center flex-1 p-2 m-1 justify-center rounded-lg shadow-md ${
                (mqttdata?.[i] ? mqttdata[i][21] : 0) > 100
                  ? "bg-green-600 text-white hover:bg-green-700 px-5 shadow-black shadow-lg"
                  : "bg-red-500 text-white hover:bg-red-600 px-5 shadow-black shadow-lg"
              } transition-colors ease-in-out duration-200`}
            >
              {(mqttdata?.[i] ? mqttdata[i][21] : 0) > 100 ? "ON" : "OFF"}
            </button>
          </p>
        </div>
        <div className="flex justify-end  p-1">
          <Link
            to="/Dashboard"
            onClick={() => handleClick(imeilist[i], device_ids[i], i)}
          >
            <button className=" p-1 bg-[#e68d26] text-sm rounded-lg text-black px-2 font-mono">
              Show more..
            </button>
          </Link>
        </div>
      </div>
    );
  }

  function scrolltoCard(value) {
    const element = document.getElementById(`card${value}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  const handleChange = (selectedOption) => {
    // selectedOption.scrollIntoView();
    let cardvalue = selectedOption.value;
    scrolltoCard(cardvalue);
    // cardno.scrollIntoView();
    console.log(selectedOption);
  };

  return (
    <div className="mt-0  ">
      {/* <div className="flex flex-wrap"></div> */}
      <div className="sticky top-0 z-50 flex p-2 bg-[#e68d26] text-center font-sans font-semibold  justify-end flex-wrap gap-6 ">
        {/* <nav className="text-white text-xl">
          DIESEL GENSET MONITORING SYSTEM
        </nav> */}
        <div className="">
          <Select options={options} onChange={handleChange} className="" />
        </div>
        {/* <UserMenu /> */}
      </div>

      <div className="min-h-screen  bg-gray-50 overflow-hidden">
        <div className="flex flex-wrap min-w-fit">{cards}</div>
      </div>
    </div>
  );
};

export default Home;
